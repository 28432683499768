import React from "react";
import Image from "next/image";
import Container from "@atoms/Container";
import Heading from "@atoms/Heading";
const EduStudentSuicide = ({
    title = "Over 35,000 Indian students have died by suicide between 2019 and 2021, as per NCRB",
    imgSrc = "/images/education/StudentStress1.svg",
    imgWidth = 1160,
    imgHeight = 610,
}) => {
    const myLoader = ({ src }) => src;
    return (
        <section className=" pt-6 pb-0 md:pt-16 ">
            <Container type="type0">
                <div className="max-w-[983px] mx-auto mb-2 md:mb-10 ">
                    <Heading type="subheading2"
                        className="text-gray-800  text-center mb-12" >
                        {title}
                    </Heading>

                    <Image
                        loader={myLoader}
                        src={imgSrc}
                        width={imgWidth}
                        height={imgHeight}
                        className=""
                        objectFit="cover"
                    />
                </div>
            </Container>
        </section>
    );
};

export default EduStudentSuicide;
