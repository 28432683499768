import PropTypes from "prop-types";
import React from 'react'

const ButtonType = ({ children, onClick, type, flex,justify, ...property }) => {

    const buttonType = {
	
        largeContained: "largeContained",
        bluButtonMsgDarkSmall: 'bluButtonMsgDarkSmall',
        largeWhiteBut: 'largeWhiteBut',
        rectangleButtonColorCustom: 'rectangleButtonColorCustom',
        addButtonSmall: 'button',
        smallButtonJacksonsPurple30: 'smallButtonJacksonsPurple30',
        smallButtonIndiainRed70:'smallButtonIndiainRed70',
        smallButtonOldSilver:'smallButtonOldSilver',
        smallButtonGreen232:'smallButtonGreen232'
		

	};

	const buttonStyle = {
        largeContained:"bg-jacksons-purple flex items-center justify-center text-white h-14 w-12 rounded-3xl",
        bluButtonMsgDarkSmall: 'bg-jacksons-purple w-40  h-10 p-2 text-white flex items-center justify-center rounded-3xl',
        largeWhiteBut: 'mb-5 md:w-4/6 rounded-3xl inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm',
        rectangleButtonColorCustom: 'mb-5 w-full rounded-3xl inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2  text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:text-sm',
        addButtonSmall:"text-lightSpray flex items-center justify-start text-white   w-[105px] h-[25px] mt-[24px] mb-[48px]",
        smallButtonJacksonsPurple30: " flex items-center justify-center   text-gray-800   w-[60px] h-[24px] mt-[34px] bg-jacksons-purple bg-opacity-30 font-bold text-xs-10 rounded-3px p-2.5",
        smallButtonIndiainRed70: " flex items-center justify-center   text-gray-800   w-[60px] h-[24px] mt-[34px] bg-indian-red bg-opacity-70 font-bold text-xs-10 rounded-3px p-2.5",
        smallButtonOldSilver: " flex items-center justify-center  text-gray-800   w-[60px] h-[24px] mt-[34px] bg-old-silver font-bold text-xs-10 rounded-3px p-2.5",
        smallButtonGreen232: " flex items-center justify-center  text-gray-800   w-[60px] h-[24px] mt-[34px] bg-green-232 font-bold text-xs-10 rounded-3px p-2.5",
        
        };



    return (
        <button type={buttonType[type]} className={`${flex || 'flex'} ${justify || 'justify'} ${property.className} ${buttonStyle[type]} ${property.disabled && 'cursor-not-allowed opacity-50 hover:opacity-50 '}`} onClick={(e) => onClick && onClick(e)} 
        {...(property.disabled && { disabled: property.disabled })} >
            {children}
        </button>
    );
};
export default ButtonType;

ButtonType.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};
