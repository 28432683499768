import Container from "@atoms/Container";
import Heading from "@atoms/Heading";
import Text from "@atoms/Text";
import EduServiceCard from "@molecules/EduServiceCard";
import React from "react";

const EduLissunFeatures = ({
    title = "We LISSUN-ed to you",
    subTitle = "We provide non-judgement expert support to help students tackle these mental health issues through",
    featureList,
}) => {
    return (
        <div className="bg-morning-glory py-16">
            <Container className={"px-3 lg:px-6"}>
                <div className="text-center">
                    <Heading type="subheading2">{title}</Heading>
                    <Text
                        className=" max-w-[800px] text-base lg:text-2xl mx-auto text-black  font-normal  mt-3 md:mt-4 mb-10  md:mb-12"
                    >
                        {subTitle}
                    </Text>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1.5 md:gap-5">
                    {featureList.map((list, index) => (
                        <EduServiceCard
                            key={index}
                            icon={list.icon}
                            title={list.title}
                        />
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default EduLissunFeatures;
