const TextField = ({ register, error, label, id, converToUpperCase, ...inputProps }) => {
  return (
    <div className="relative w-full mb-3 ">
      <label style={{ minWidth: '200px' }} className={`block text-blueGray-600 text-xs font-bold mb-2 float-left ${inputProps.labelClassName}`} htmlFor="grid-password">{label}</label>
      {
        converToUpperCase ? <input onInput={(e) => e.target.value = e.target.value.toUpperCase()}
          {...register(id)} id={id} {...inputProps}
          className={`border border-solid focus:border-0  border-blueGray-300 px-3 py-2 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 
          ${inputProps.disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'} ${inputProps.className}`} /> :
          <input
            {...register(id)} id={id} {...inputProps}
            className={` border border-solid focus:border-0 border-blueGray-300 px-3 py-2 placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 
          ${inputProps.disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'} ${inputProps.className}`} />
      }

      {error && <div className="mt-4 text-red-600 text-[10px] font-medium capitalize	">{error.message}</div>}
    </div>
  )
}
export default TextField;

TextField.defaultProps = {
  converToUpperCase: false
}